<template>
  <div v-loading="loading">
    <div class="examin-content" :class="{ bg2: !list }" v-if="testInfo">
      <div class="examinLeft">
        <p class="examinTitle">{{ testInfo && testInfo.name }}</p>
        <p class="examinDesc">注：每人有三次上传机会</p>
        <p class="examinDesc" v-if="testInfo.time">
          <img
            src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/examin2.png"
          />上传时间：{{ testInfo.time }}
        </p>
      </div>
      <!-- video_status  0 待审核  1合格  2 良好  3优秀 4未通过  5 未提交 -->
      <div class="isPass" v-if="testInfo.video_status!=5">
          <img :src="pass" v-if="testInfo.video_status == 1 || testInfo.video_status == 2 || testInfo.video_status == 3"/>
          <img :src="noPass" v-if="testInfo.video_status == 4"/>
          <img :src="wait" v-if="testInfo.video_status == 0"/>
      </div>
      <div class="examinBtn">
        
        <div class="btn">
          <p v-if="testInfo.video_status == 5"  @click="toExamin()">开始答题</p>
          <p  @click="toExamin()" v-else-if="testInfo.video_status == 4 || testInfo.video_status == 0">重新作答</p>
        </div>
      </div>
    </div>
  </div>
</template>  
<script>
import myArea from '@/components/area.vue'
import formSubmitImg from '@/assets/images/formSubmitImg.png'
import wait from '@/assets/images/wait.png'
export default {
  props: ["testInfo", "list"],
  components:{myArea},
  data() {
    return {
      progress: 0,
      loading: false,
      form: {
        gender:3,
        name: this.$store.state.user.nickname,
        mobile: this.$store.state.user.mobile,
        cid: "",
        cover: "",
        duties:""
      },
      wait,
      formImg: formSubmitImg,
      infoSubmit: false,
      // testInfo:{
      //   exam_pass: 0,
      //   name:'试卷题目',
      //   score: 100,
      //   question_number: 10,
      //   pass: 0,
      //   exam_time: '2022-10-10 12:12:12'
      // },
      pass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/pass.jpg",
      noPass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/noPass.jpg",
    };
  },
  computed: {
    status(){
      let status = false
      if(this.list){
        status = this.list.length ? this.list.every(item=>{
          return item.study_status == 1 || item.speak.length && item.speak.every(v=>{ return v.study_status == 1})
        }) : false
      
      }else{
        status = true
      }
      return this.testInfo.study_status == 1 || status
    },
    vipInfo() {
      return this.$store.state.vipInfo;
    },
    platName() {
      return this.$store.state.plat.name;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    VIPShow() {
      return this.$store.state.VIPShow;
    },
   
  },
  mounted() {},
  methods: {
    changeAdd(info){
      console.log(info)
      this.form.province_id = info[0].id
      this.form.province_name = info[0].name
      this.form.city_id = info[1].id
      this.form.city_name = info[1].name
      this.form.district_id = info[2].id
      this.form.district_name = info[2].name
    },
     gettime(video, size){
      const promise = new Promise(resolve => {
        video.addEventListener('canplaythrough', e => {
          if (e.target.duration <= size) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
      return promise
    },
    async checkSize(files, size) {
      // console.log(56, Number.isNaN(Number(size)), size)
      if (!files) return false
      // 这一条是正式服务器不需要这段所以当size 为undefined 时默认 返回 true
      if (Number.isNaN(Number(size))) return true
      const checktimevideo = document.getElementById('checktimevideo')
      if (checktimevideo) {
        document.body.removeChild(checktimevideo)
      }
      const video = document.createElement('video')
      const url = URL.createObjectURL(files)
      video.src = url
      video.id = 'checktimevideo'
      video.style.display = 'none'

      document.body.appendChild(video)

      return await this.gettime(video, size)
    },
    uploadData2(){
      this.$toast("请完成所有课程再提交");
    },
    toView(){
      window.open(this.testInfo.video_url)
    },
    async toExamin() {
      let baseInfo = this.$parent.baseInfo || this.$parent.$parent.baseInfo;
      // if (!this.testInfo.is_message) {
      //   this.infoSubmit = true;
      //   return;
      // }
      window.localStorage.setItem('videoInfo',JSON.stringify(this.testInfo))
      this.$router.push('./examinVideoUpload')
    },
    formSubmit() {
      // if(this.$until.checkPhone(this.form.mobile)!='ok'){
      //   this.$toast(this.$until.checkPhone(this.form.mobile));
      //   return
      // }
      this.form.uid = this.$store.state.user.id
      if(this.$until.checkCard(this.form.cid)!='ok'){
        this.$toast(this.$until.checkCard(this.form.cid));
        return
      }
      if (Object.values(this.form).findIndex((item) => !item) != -1) {
        this.$toast("请补全全部信息");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$request.userInfoSubmit(this.form).then((res) => {
        loading.close();
        if (res.code == 0) {
          window.localStorage.setItem('videoInfo',JSON.stringify(this.testInfo))
          this.$router.push('./examinVideoUpload')
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.formMain {
  color: #333333;
  overflow: hidden;
  > p {
    text-align: center;
    font-size: 18px;
    margin-bottom: 36px;
  }
  .btn {
      width: 260px;
      height: 36px;
      cursor: pointer;
      margin: 20px auto 0;
      background: #0080d6;
      color: #fff;
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      border-radius: 18px;
    }
  .form {
    display: flex;
    .formLeft {
      padding-left: 30px;
      margin-right: 50px;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      .line {
        display: flex;
        align-items: center;
        width: 316px;
        margin-bottom: 15px;
        > p {
          font-size: 16px;
          width: 68px;
          text-align: justify;
          text-align-last: justify;
          margin-right: 3px;
        }
        > span {
          font-size: 16px;
        }
        > div {
          margin-left: 11px;
          padding-left: 10px;
          flex: 1;
          border-bottom: 1px solid #e4e8ed;
          line-height: 30px;
          height: 32px;
          display: flex;
          align-items: center;
          input {
            border: 0;
            height: 30px;
            background: transparent;
          }
          p {
            width: 79px;
            display: flex;
            align-items: center;
            color: #999999;
            cursor: pointer;
            img {
              margin-right: 8px;
            }
          }
          p.sexActive {
            color: #666;
          }
        }
      }
    }
    
    .formRight {
      width: 124px;
      height: 173px;
      position: relative;
      margin-left: 40px;
      border-radius: 8px;
      overflow: hidden;
      flex-shrink: 0;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }

  
}
.examin-content {
  display: flex;
  align-items: center;
  width: 780px;
  margin: 0 10px;
  background: #e6f3fc;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 30px 20px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #666666;
  position: relative;
  .process{
    position: absolute;
    left: 20px;
    width: 760px;
    bottom: 10px;
  }
  >p{
    text-align: center;
    width: 30%;
  }
  p.green{
    color: #5FD131;
  }
  p.red{
    color: #FF4D41;
  }
}
.bg2 {
  background: #f7f7f7;
}
.examin-content .examinBtn {
  width: 122px;
  text-align: center;
  padding: 10px 0;
  color: #fff;
  border-radius: 5px;
  position: relative;
    input{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
      opacity: 0;
    }
  .isPass {
    margin: 0 auto 20px;
    cursor: pointer;
    height: 37px;
  }
  
  .btn {
    width: 100%;
    height: 34px;
    line-height: 34px;
    background: #fff;
    color: #0080d6;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: #0080d6;
      color: #fff;
    }
    
  }
}
.opacity {
  opacity: 0.5;
}
.examin-content .examinLeft {
  flex: 1;
}
.examin-content .examinLeft .examinTitle {
  color: #222;
  width: 100%;
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: bold;
}
.examin-content .examinLeft .examinDesc {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  font-size: 14px;
  img {
    margin-right: 8px;
  }
}
.examin-content .examinLeft .score span {
  margin-right: 50px;
}
.isPass{
  margin-right: 85px;
}
</style>
