<template>
<div class="lesson" ref="cert" :class="{noHeight:type}">
  <el-dialog :title="lessonNm" :visible.sync="dialogTableVisible">
      <div style="height: 480px; overflow: auto">
        <img
          @contextmenu="$event => $event.preventDefault()"
          :src="certUrl"
          alt=""
        />
      </div>
    </el-dialog>
  <canvas :id="canvasIndex" :width="width" :height="height"></canvas>
  <img :src="certUrl" :preview-src-list="[...certUrl]" @click="toViewImg"  @contextmenu="$event => $event.preventDefault()"/>
  <p class="btn" @click="down">点击下载电子证书</p>
</div>
</template>

<script>
  import certImg from '@/assets/images/cert.png'
  import certImg1 from '@/assets/images/cert1.jpg'
  import certImg2 from '@/assets/images/cert.png'
  import certZhang from '@/assets/images/certZhang.png'
export default {
  name: "lesson",
  props:['lessonNm','type','index', 'certInfoItem', 'userInfoItem','certImg'],
  data() {
    return {
      dialogTableVisible: false,
      certInfo: {},
      userInfo:{
        gender: 2,
        name:'张一一',
        cid: '455652199602152256',
        coverImg: 'https://upload.jianshu.io/users/upload_avatars/6631354/f61123b8-e6b6-4c8f-a207-ad78c8614286?imageMogr2/auto-orient/strip|imageView2/1/w/100/h/100/format/webp',
        birthday: '1990-03-25',
        id: '5658965454',
        certTime: '2022-11-25'
      },
      coverImg:'https://cdn.wormhoo.com/images/2022-12-23/2022122314281833006.jpg',
      width:1600,
      height:1208,
      certImg1,
      certImg2,
      certZhang,
      certUrl:'',
      canvas:null,
      ctx:null,
      bg1:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/%E5%88%B0%E5%AE%B6%E9%AB%98%E7%BA%A7%E5%A9%B4%E5%B9%BC%E5%B8%88-%E6%A0%B7%E6%9C%AC.jpg',
    };
  },
  watch:{
    index:{
      handler(){
        if(this.index || this.index === 0){
          this.certInfo = this.certInfoItem
          this.userInfo = this.userInfoItem
          this.$nextTick(()=>{
            this.getData()
          })
          
        }
      },
      immediate: true
    },
   
  },
  async mounted() {
    // this.getData();
    
  },
  destroyed() {
    window.removeEventListener("scroll", this.onLoad);
  },
  computed: {
    canvasIndex(){
       return this.index || this.index === 0 ? 'canvas'+this.index : 'canvas'
    },
    plat() {
      return this.$store.state.plat_id;
    },
  },
  methods: {
    toViewImg() {
      if(this.index || this.index === 0){
        this.dialogTableVisible = true;
      }
    },
    async getData(id) {
      if(id){
        console.log('1111')
        let res = await this.$request.certInfo(id)
        this.certInfo = res.data.cret_list
        this.userInfo = res.data.user_list
      }
      this.canvas=document.getElementById(this.canvasIndex);
      this.ctx=this.canvas.getContext("2d");
      this.width = 1500
        this.height = 1060
      this.setCavans1()
      // if(id == '588'){
      //   this.width = 1082
      //   this.height = 774
      //   this.setCavans2()
      // }else{
      //   this.setCavans1()
      // }
      
    },
    setCavans1() {
      const image = new Image();
      // image.src = this.certImg1
      console.log(this.certInfo.cover)
      image.src = this.certInfo.cover
      image.setAttribute("crossOrigin",'Anonymous')
      console.log(this.canvas.width)
      image.onload = ()=> {
        this.ctx.drawImage(image, 0, 0,this.canvas.width, this.canvas.height);

        
        // 课程名称
        this.ctx.font = "36px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(this.lessonNm, 690, 540);
        
          // 左边姓名
          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'center'
          this.ctx.fillText(this.userInfo.name, 232, 440);


          // 证书编号
          this.ctx.font = "40px Microsoft YaHei";
          this.ctx.fillStyle = '#333';
          this.ctx.textAlign = 'left'
          this.ctx.fillText(this.certInfo.cert_no, 300, 905);
        console.log('3333')

          this.certUrl = this.canvas.toDataURL("image/png");
      }
    },
    setCavans2() {
      const image = new Image();
      image.setAttribute("crossOrigin",'Anonymous')
      image.onload = ()=> {
        this.ctx.drawImage(image, 0, 0,this.canvas.width, this.canvas.height);
        
        // 左边姓名
        this.ctx.font = "26px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(this.userInfo.name, 176, 390);

        // 证书编号
        this.ctx.font = "30px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'left'
        this.ctx.fillText('NO.'+this.certInfo.cert_no, 122, 680);

        let certTimeArr = this.certInfo.day_time.split('-')
        // 右边年月
        this.ctx.font = "24px Microsoft YaHei";
        this.ctx.fillStyle = '#333';
        this.ctx.textAlign = 'center'
        this.ctx.fillText(certTimeArr[0]+'年'+certTimeArr[1]+'月'+certTimeArr[2]+'日', 750, 624);

        let zhang = new Image()
        zhang.onload = ()=>{
          this.ctx.drawImage(zhang, 695, 515,150, 150);
          this.certUrl = this.canvas.toDataURL("image/png");
        }
        zhang.src = this.certZhang

      }
      image.src = this.certImg2;
    }, 
    down(){
      let base64URL = this.certUrl
      let fileName = '培训证书'
        // 创建a标签，用于触发下载  
      const a = document.createElement('a');  
      // 将 a 标签的 download 属性设置为要下载的文件名  
      a.download = fileName || 'image';  
      // 创建 Blob 对象，并获取 base64 数据的 MIME 类型  
      const mimeType = base64URL.match(/:(.*?);/)[1];  
      // 将 base64 数据转换为字节数组  
      const byteCharacters = atob(base64URL.split(',')[1]);  
      const byteNumbers = new Array(byteCharacters.length);  
      // 将字节数组填充到 Uint8Array 中  
      for (let i = 0; i < byteCharacters.length; i++) {  
        byteNumbers[i] = byteCharacters.charCodeAt(i);  
      }  
      const byteArray = new Uint8Array(byteNumbers);  
      // 创建 Blob 对象  
      const blob = new Blob([byteArray], {type: mimeType});  
      // 将 Blob 对象的 URL 赋值给 a 标签的 href 属性  
      a.href = URL.createObjectURL(blob);  
      // 将a标签暂时添加到 body 中，触发下载  
      document.body.appendChild(a);  
      a.click();  
      // 下载完成后，将 a 标签从 body 中移除  
      document.body.removeChild(a);  

    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.btn{
  margin: 10px auto;
  width: 280px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  background: #0080d6;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.lesson {
  width: 100%;
  min-height: calc(100vh - 230px);
  text-align: center;
  overflow: hidden;
  canvas{
    opacity: 0;
    position: absolute;
    left: -9999px;
  }
  img{
    width: auto;
    max-width: 100%;
  }
}
.noHeight{
  height: auto;
  min-height: 0;
  img{
    width: auto;
    max-width: 100%;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
</style>
