<template>
  <div>
    <div class="lesson-item" @click="jumpDetail(item)" v-for="(item,index) of list" :key="index">
            <div class="free" v-if="item.is_buy != 1 && item.is_show == 1">
              试看
            </div>
            <div class="lesson-video">
              <img :src="item.cover || $placeImg" alt="">
            </div>
            <div class="lesson-audio">
              <div class="lesson-title">{{item.name}}</div>
        
                <!-- {{lesson && item.resource[0].duration}} -->
                <div class="lesson-msg">
                  
                  <div class="lesson-time" >
                    <img
                      src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/gaoyanyuan/d5.png"
                      alt=""
                    />{{ item.video_ids[0].resource[0].ali_duration}}
                  </div>
                 
                </div>
            </div>
            <div class="play-btn" >播放</div>
          </div>
  </div>
  
</template>
<script>

export default {
  props:['list','isBuy'],
  data(){
    return{
      height:0,
      top:0,
    }
  },
  computed:{
    vipInfo(){
      return this.$store.state.vipInfo
    },
    platName(){
      return this.$store.state.plat.name
    }
  },
  mounted(){
  },
  methods:{
    jumpDetail(item) {
      if(this.isBuy==0){
        this.$toast('请购买后开始学习')
        return
      }
      let url = './player2?type=1&chapter_id='+item.id+'&id='+this.$route.query.id
      this.$router.push(url)
    },
  }
}
</script>

<style scoped lang="scss">
.blue, .red, .green{
    display: inline-block;
    margin-left: 10px;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    border-radius: 3px;
    font-size: 12px;
    
  }
  .blue{
    background: #cee7f8;
    color: #0080d6;
  }
  .lesson-item {
        position: relative;
        height: 82px;
        border-radius: 10px;
        background: #f9f9f9;
        overflow: hidden;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0 10px;
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 10px;
        &:last-of-type {
          border: none;
        }
      .free {
        width: 40px;
        height: 28px;
        background: #ff5d5e;
        border-radius: 5px;
        line-height: 28px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        position: absolute;
        right: 0px;
        top: 0px;
      }
        .play-btn {
          width: 108px;
          height: 34px;
          background: #fff;
          color: #006bb3;
          font-size: 14px;
          text-align: center;
          line-height: 34px;
          border-radius: 5px;
          &:hover{
            background: #006bb3;
            color: #fff;
          }
        }

        .lesson-audio {
          flex: 1;
          margin: auto 0;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          height: 60px;
        }

        .lesson-audio .lesson-title {
          line-height: 22px;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          position: relative;
          font-size: 16px;
          display: -webkit-box;
          overflow: hidden;
          margin-bottom: 5px;
        }

       
        .lesson-msg {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 26px;
          color: #666;
          width: 100%;
        }

        .lesson-msg .lesson-time,
        .lesson-msg .lesson-count,
        .lesson-msg .lesson-status {
          display: flex;
          align-items: center;
          color: #666;
          white-space: nowrap;
        }

        .lesson-msg img {
          height: 14px;
          margin-right: 5px;
        }

        .lesson-video {
          position: relative;
          float: left;
          margin-right: 10px;
        }

        .lesson-video img {
          display: block;
          width: 108px;
          height: 60px;
          border-radius: 5px;
        }

        .lesson-video .video-duration {
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: 10px;
          font-weight: 500;
          color: #fff;
          height: 32px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          padding: 0 3px 3px 0;
        }

       
      }
</style>